import {
  BrowserRouter,
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
  redirect,
  Navigate,
} from 'react-router-dom';
import axios from './plugins/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPermissions, setUser } from './store/user';

// components
import Layout from './components/Layout';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { apiUrl, localApiUrl } from './constants/index';

// pages
import Auth from './pages/Auth/Auth';
import Payments from './pages/Payments/Payments';
import Cars from './pages/Car/Cars';
import Profile from './pages/Profile/Profile';
import Deals from './pages/Deal/Deals';
import DealMainInfo from './pages/Deal/DealMainInfo';
import DealNavigation from './pages/Deal/DealNavigation';
import DealAutos from './pages/Deal/DealAutos';
import DealEquipments from './pages/Deal/DealEquipments';
import DealServices from './pages/Deal/DealServices';
import DealDocs from './pages/Deal/DealDocs';
import NewDealAuto from './pages/Deal/NewDeal/NewDealAuto';
import NewDealEqupment from './pages/Deal/NewDeal/NewDealEqupment';
import NewDealDocs from './pages/Deal/NewDeal/NewDealDocs';
import NewDealService from './pages/Deal/NewDeal/NewDealService';
import Leads from './pages/Leads/Leads';
import LeadMainInfo from './pages/Leads/LeadMainInfo';
import LeadClient from './pages/Leads/LeadClient';
import LeadsNavigation from './pages/Leads/LeadsNavigation';
import LeadCreate from './pages/Leads/NewLead/LeadCreate';
import NewLeadClient from './pages/Leads/NewLead/NewLeadClient';
import Reports from './pages/Reports/Reports';
import ReportCreate from './pages/Reports/NewReport/ReportCreate';
import Records from './pages/Records/Records';
import RecordService from './pages/Records/RecordService';
import AddRecordService from './pages/Records/NewRecord/AddRecordService';
import AddRecordTestdrive from './pages/Records/NewRecord/AddRecordTestdrive';
import ServiceRecords from './pages/Records/ServiceRecords';
import TestDriveRecords from './pages/Records/TestDriveRecords';
import Clients from './pages/Client/Clients';
import ClientMainData from './pages/Client/ClientMainData/ClientMainData';
import ClientDocs from './pages/Client/ClientDocs';
import ClientDeals from './pages/Client/ClientDeals';
import ClientRecords from './pages/Client/ClientRecords';
import ClientCreate from './pages/Client/NewClient/ClientCreate/ClientClreate';
import NewClientsDocs from './pages/Client/NewClient/NewClientsDocuments';
import ClientsNavigation from './pages/Client/ClientsNavigation';
import NewClientDeals from './pages/Client/NewClient/NewClientsDeal';
import NewClientsRecord from './pages/Client/NewClient/NewClientsRecord';
import Equipments from './pages/Equipments/Equipments';
import AddEquipments from './pages/Equipments/AddEquipments';
import Services from './pages/Services/Services';
import AddService from './pages/Services/AddService';
import CarRoutes from './pages/Car/CarRoutes';
import LeadEdit from './pages/Leads/LeadEdit';
import ClientNotary from './pages/NotaryPages/ClientNotary';
import DealNotary from './pages/NotaryPages/DealNotary';
import { store } from '.';
import Permissions from './pages/Settings/Permissions/Permissions';
import { useEffect, useState } from 'react';
import getRoles from './api/roles/getRoles';
import historyObject from './utils/historyObject';
import DealCredits from './pages/Deal/DealCredits';
import DealCreate from './pages/Deal/DealCreate/DealCreate';
import Sign from './pages/Public/Sign';
import LogRocket from 'logrocket';
import EditEquipment from './pages/Equipments/EditEquimpent';
import EditService from './pages/Services/EditService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToasyifyRewrite.css';
import warning from './assets/warning.svg';
import NotaryClientsNavigation from './pages/NotaryPages/Client/NotaryClientsNavigation';
import NotaryClients from './pages/NotaryPages/Client/NotaryClients';
import isUserHaveRole from './utils/isUserHaveRole';
import { ROLE_NOTARY } from './constants/roles';
import Loader from './components/Loader/Loader';
import NotaryDeals from './pages/NotaryPages/Deal/NotaryDeal';
import NotaryDealNavigation from './pages/NotaryPages/Deal/NotaryDealNavigation';
import NotaryDealsCredit from './pages/NotaryPages/Deal/NotaryDealCredit';
import DealCreateSellEquipment from './pages/Deal/DealCreate/SellEquipment/DealCreateSellEquipment';
import SignDocuments from './pages/Public/SignDocuments';
import History from './pages/document/History';
import DeleteAccount from './pages/Public/DeleteAccount';

const uri = localApiUrl + '/';
export let socket = new HubConnectionBuilder().withUrl(uri + 'connectCrm').build();
function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let id = 0;
  async function checkAuthenticate() {
    if (window.location.pathname === '/sign' || window.location.pathname.includes('signDocuments') || window.location.pathname.includes('document/history') || window.location.pathname.includes('delete-account')) {
      return setLoading(false);
    }
    await axios
      .get('/api/user?time=' + new Date().getTime())
      .then((res) => {
        dispatch(setUser({ ...res.data }));
        id = res.data.id;

        LogRocket.identify(res?.data?.id, {
          name: res?.data?.name + ' ' + res?.data?.surname,
          ...res?.data,
        });
      })
      .finally(() => setLoading(false));
    getRoles().then((res) => {
      dispatch(setPermissions(res.data));
    });
  }

  setTimeout(() => {
    socket.start().then(() => {
      socket.invoke('connection', id);
    });
  }, 0);

  useEffect(() => {
    checkAuthenticate();
    getRoles().then((res) => {
      dispatch(setPermissions(res.data));
    });
    socket.on('listenToChangeDealStatus4', (data) => {
      console.log('testsock');
    });
  }, [dispatch]);

  useEffect(() => {
    LogRocket.init('a-livse/doscar');
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <HistoryRouter history={historyObject}>
        <Routes>
          <Route path="/sign" element={<Sign />} />
          <Route path="/signDocuments/:clientId/:signUrl/:signType" element={<SignDocuments />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/document/history/:documentId" element={<History />} />
          <Route path="/authorization" element={<Auth />} />
          <Route path="/poles/:id/payment" element={<Payments />} />
          <Route path="/" element={<Layout />}>
            {isUserHaveRole(ROLE_NOTARY) && <Route index element={<Navigate to="/notary/clients" />} />}
            {!isUserHaveRole(ROLE_NOTARY) && <Route index element={<Clients />} />}
            <Route path="leads" element={<Leads />} />
            <Route path="deals" element={<Deals />} />
            <Route path="deals-rejected" element={<Deals routerParams={{statusId: 23}}/>} />
            <Route path="cars" element={<Cars />} />
            <Route path="profile" element={<Profile />} />
            <Route path="client" element={<ClientsNavigation />}>
              <Route path=":id/main-data" element={<ClientMainData />} />
              <Route path=":id/documents" element={<ClientDocs />} />
              <Route path=":id/deals" element={<ClientDeals />} />
              <Route path=":id/records" element={<ClientRecords />} />
              <Route path="create" element={<ClientCreate />} />
              <Route path="documents" element={<NewClientsDocs />} />
              <Route path="deals" element={<NewClientDeals />} />
              <Route path="records" element={<NewClientsRecord />} />
            </Route>
            <Route path="leads" element={<LeadsNavigation />}>
              <Route path="create" element={<LeadCreate />} />
              <Route path="client" element={<NewLeadClient />} />
              <Route path="lead/:id/main-info" element={<LeadMainInfo />} />
              <Route path="lead/:id/client" element={<LeadClient />} />
              <Route path="lead/edit/:id" element={<LeadEdit />} />
            </Route>
            <Route path="deals" element={<DealNavigation />}>
              <Route path="deal/:id/main-info" element={<DealMainInfo />} />
              <Route path="deal/:id/autos" element={<DealAutos />} />
              <Route path="deal/:id/equipments" element={<DealEquipments />} />
              <Route path="deal/:id/services" element={<DealServices />} />
              <Route path="deal/:id/documents" element={<DealDocs />} />
              <Route path="deal/:id/credit" element={<DealCredits />} />
              <Route path="autos" element={<NewDealAuto />} />
              <Route path="equipments" element={<NewDealEqupment />} />
              <Route path="documents" element={<NewDealDocs />} />
              <Route path="services" element={<NewDealService />} />
            </Route>
            <Route path="deals/create" element={<DealCreate />} />
            <Route path="deals/equipment" element={<DealCreateSellEquipment />} />
            <Route path="cars/*" element={<CarRoutes />} />
            <Route path="equipments" element={<Equipments />} />
            <Route path="equipments/:id" element={<EditEquipment />} />
            <Route path="equipments/add-equipment" element={<AddEquipments />} />
            <Route path="services" element={<Services />} />
            <Route path="services/add-service" element={<AddService />} />
            <Route path="services/:id" element={<EditService />} />
            <Route path="records" element={<Records />} />
            <Route path="records/add-record-service" element={<AddRecordService />} />
            <Route path="records/add-record-testdrive" element={<AddRecordTestdrive />} />
            <Route path="records/service" element={<ServiceRecords />} />
            <Route path="records/test-drive" element={<TestDriveRecords />} />
            <Route path="records/service/:id" element={<RecordService />} />
            <Route path="reports" element={<Reports />} />
            <Route path="reports/create" element={<ReportCreate />} />

            <Route path="settings">
              <Route path="permissions" element={<Permissions />} />
            </Route>

            <Route path="notary">
              <Route path="clients" element={<NotaryClientsNavigation />}>
                <Route path="" element={<NotaryClients />} />
                <Route path=":id/main-data" element={<ClientNotary />} />
              </Route>
              <Route path="deals" element={<NotaryDealNavigation />}>
                <Route path="" element={<NotaryDeals />} />
                <Route path="prepayment" element={<NotaryDeals />} />
                <Route path="credit" element={<NotaryDealsCredit />} />
                <Route path=":id/main-info" element={<DealNotary />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </HistoryRouter>
      <ToastContainer
        position="top-center"
        style={{
          maxWidth: 700,
          width: '100%',
        }}
        icon={<img src={warning} alt="Icon" />}
        hideProgressBar
        autoClose={10000}
      />
    </>
  );
}
export default App;
