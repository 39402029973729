import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import createComplectation from '../../../api/cars/createComplectation';
// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';
import styles from '../../../components/Form/form.module.css';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
// icons
import icon from '../../../assets/sidebar/cars.svg';
import HookForm from '../../../components/HookForm/HookForm';
import getComplectations from '../../../api/cars/getComplectations';
import SelectEngine from '../../../components/Selects/SelectEngine';
import SelectCarcase from '../../../components/Selects/SelectCarcase';
import editComplectation from '../../../api/cars/editComplectation';
import SelectWd from '../../../components/Selects/SelectWd';
import { textStyles } from '../../../constants/textStyles';
import LoaderInline from '../../../components/Loader/LoaderInline';
import alert from '../../../helpers/alert';
import SelectPower from '../../../components/Selects/SelectPower';

const formStyles = {
  complectation: {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  equipments: {},
};

const additionalEquipments = Array.from({ length: 5 }, (_, index) => ({
  title: "",
  name: `equip-${index + 1}`
}));

const CarComplectationsEdit = () => {
  const { containerId, complectationId } = useParams();

  const [complectation, setComplectation] = useState([]);
  const [safely, setSafely] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const init = () => {
    getComplectations({ containerId })?.then((res) => {
      const item = res?.data?.find((i) => i?.id == complectationId);

      setComplectation([
        {
          name: 'title',
          label: 'Комплектация',
          placeholder: 'Выберите из списка',
          defaultValue: item?.title,
        },
        {
          name: 'engineId',
          label: 'Тип топлива',
          component: SelectEngine,
          placeholder: 'Выберите из списка',
          defaultValue: { value: item?.engine.id, label: item?.engine.title },
        },
        {
          name: 'carcaseId',
          label: 'Тип топлива',
          component: SelectCarcase,
          placeholder: 'Выберите из списка',
          defaultValue: { value: item?.carcase.id, label: item?.carcase.title },
        },
        {
          name: 'wdId',
          label: 'Тип привода',
          component: SelectWd,
          placeholder: 'Выберите из списка',
          defaultValue: { value: item?.wd.id, label: item?.wd.title },
        },
        {
          name: 'engine_volume',
          label: 'Объем двигателя',
          placeholder: 'Выберите из списка',
          defaultValue: item?.engine_volume,
        },
        {
          name: 'power_value',
          label: 'Л.C.',
          defaultValue: item?.power_value,
        },
        {
          name: 'transmission',
          label: 'Коробка передач',
          defaultValue: item?.transmission,
        },
        {
          name: '',
          component: () => <p style={textStyles.formTitle}>Оборудование</p>,
          style: { gridColumn: '1 / span 3' },
        },
      ]);

      const equipmentInputs =
        [...item?.complectation_equipment, ...additionalEquipments].map((equipment, index) => ({
          name: `equipment_${equipment.id || equipment.name}`,
          label: 'Оборудование ' + (index + 1),
          placeholder: 'Введите название оборудования',
          defaultValue: equipment.title,
          style: { gridColumn: '1 / span 3' },
        //   removable: true,
        //   onRemove: onRemoveEquipment,
        })) || [];

      const safelyInputs =
        [...item?.complectation_safely, ...additionalEquipments].map((equipment, index) => ({
          name: `comfort_${equipment.id || equipment.name}`,
          label: 'Оборудование ' + (index + 1),
          placeholder: 'Введите название оборудования',
          defaultValue: equipment.title,
          style: { gridColumn: '1 / span 3' },
        })) || [];

      setEquipments(equipmentInputs);
      setSafely(safelyInputs);
    });
  }

  useEffect(() => {
    setComplectation([]);
    setEquipments([]);
    setSafely([]);
    init();
  }, []);


  const save = async (values) => {
    console.log(values);
    try {
      await editComplectation(complectationId, {
        title: values?.title,
        wdId: values?.wdId?.value,
        engineId: values?.engineId?.value,
        carcaseId: values?.carcaseId?.value,
        power_value: values?.power_value,
        engine_volume: values?.engine_volume,
        transmission: values?.transmission,
        complectation_equipment: Object.keys(values)
          .filter((key) => key.startsWith('equipment_') && (values[key] || +key.split('_')?.[1]))
          .map((key) => ({
            id: +key.split('_')[1] || undefined,
            title: values[key],
          })),
        complectation_safely: Object.keys(values)
          .filter((key) => key.startsWith('comfort_') && (values[key] || +key.split('_')?.[1]))
          .map((key) => ({
            id: +key.split('_')[1] || undefined,
            title: values[key],
          })),
      });
      alert.success("Данные успешно сохранены");
      init();
    } catch(e) {
      alert.error(e);
    }
  };

  if (complectation?.length == 0) {
    return <LoaderInline />;
  }

  return (
    <>
      <Header title={'Комплектация ' + complectationId} icon={icon} />
      <div style={{ paddingBottom: '20px', marginTop: 20 }}>
        <HookForm
          title={'Данные клиента'}
          grid={formStyles.complectation}
          border
          inputs={[
            ...complectation,
            ...equipments,
            {
              name: '',
              component: () => <p style={textStyles.formTitle}>Комфорт и оборудование</p>,
              style: { gridColumn: '1 / span 3' },
            },
            ...safely,
          ]}
          style={{ margin: 0 }}
          onSubmit={save}
          action={'Сохранить'}
        />
        {/* <HookForm
          title={'Данные клиента'}
          grid={formStyles.complectation}
          border
          inputs={equipments}
          style={{ margin: 0 }}
          onSubmit={save}
          action={'Сохранить'}
        /> */}
        {/* <div className={styles.borderWrapper}>
          <h1>Безопасноть</h1>
          <br />
          {safely.map((item, index) => (
            <div style={{ marginBottom: 15 }}>
              <Input
                label={'Название'}
                placeholder={'Введите значение'}
                onChange={(e) => {
                  safely[index].title = e.target.value;
                  setSafely([...safely]);
                }}
              />
            </div>
          ))}
          <Button
            onClick={() => {
              safely.push({ title: '' });
              setSafely([...safely]);
            }}
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
            text={'Добавить'}
            fill
          />
          <h1>Комфорт и оборудование</h1>
          {equipments.map((item, index) => (
            <div style={{ marginBottom: 15 }}>
              <Input
                label={'Название'}
                placeholder={'Введите значение'}
                onChange={(e) => {
                  equipments[index].title = e.target.value;
                  setEquipments([...equipments]);
                }}
              />
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                equipments.push({ title: '' });
                setEquipments([...equipments]);
              }}
              style={{
                marginTop: 15,
                marginBottom: 15,
              }}
              text={'Добавить'}
              fill
            />
            <Button
              onClick={() => createComplectationCar()}
              style={{
                marginTop: 15,
                marginBottom: 15,
              }}
              text={'Сохранить'}
              fill
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CarComplectationsEdit;
